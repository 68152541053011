import {
  createSlice,
  createAsyncThunk,
  createAction,
  PayloadAction,
} from '@reduxjs/toolkit';
import { LoginState, UserData } from 'shared/models';
import { apiSlice } from '../../services/api';

const initialState: LoginState = {
  user: null,
  isLoading: false,
  error: null,
  isAuthChecked: false,
  isAuth: false,
};

export const resetState = createAction('RESET_STATE');

export const checkAuth = createAsyncThunk<
  UserData,
  void,
  {
    rejectValue: string
  }
>(
  'auth/checkAuth',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      // Проверяем наличие токена
      const token = localStorage.getItem('token');
      if (!token) {
        return rejectWithValue('No token found');
      }

      const result = await dispatch(apiSlice.endpoints.refresh.initiate(null)).unwrap();
      if (!result?.data) {
        return rejectWithValue('Invalid response from server');
      }

      localStorage.setItem('token', result.data.accessToken);
      
      // Преобразуем пустые объекты в пустые строки
      const user = result.data.user;
      Object.keys(user).forEach(key => {
        if (user[key] && typeof user[key] === 'object' && Object.keys(user[key]).length === 0) {
          user[key] = '';
        }
      });
      
      return user;
    } catch (error: any) {
      console.error('Auth check failed:', error);
      localStorage.removeItem('token');
      return rejectWithValue(error?.message || 'Auth check failed');
    }
  }
);

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserData>) => {
      state.user = action.payload;
      state.isAuthChecked = true;
      state.isAuth = true;
      localStorage.setItem('user', JSON.stringify(action.payload));
    },
    logoutUser: (state) => {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      state.user = null;
      state.isAuth = false;
      state.isAuthChecked = true;
    },
    updateUser: (state, action: PayloadAction<Partial<UserData>>) => {
      if (state.user) {
        state.user = { ...state.user, ...action.payload };
        localStorage.setItem('user', JSON.stringify(state.user));
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkAuth.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(checkAuth.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.user = action.payload;
        state.error = null;
        state.isAuthChecked = true;
      })
      .addCase(checkAuth.rejected, (state, action) => {
        state.isLoading = false;
        state.error = typeof action.payload === 'string' ? action.payload : 'Authorization error';
        state.isAuthChecked = true;
      });
  },
});

export const { setUser, logoutUser, updateUser } = loginSlice.actions;

export default loginSlice.reducer;
