import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetByHashQuery } from 'services/api';
import { formatPhoneNumber } from 'shared/lib/helpers/formatPhoneNumber';
import { formatDate } from 'shared/lib/helpers/formatDate';
import { Container } from 'components/ui/Container';

import styles from './PublicBidPage.module.scss';
// import ButtonUI from 'components/ui/ButtonUI/ButtonUI';

export const PublicBidPage: React.FC = () => {
    const { hash } = useParams<{ hash: string }>();
    const { data: bid, isLoading, error } = useGetByHashQuery(hash || '');

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return (
            <div className={styles.errorContainer}>
                <h2>Ошибка</h2>
                <p>Заявка не найдена или срок действия ссылки истек</p>
            </div>
        );
    }

    const getStatusText = (status: string) => {
        const statusMap: { [key: string]: string } = {
            draft: 'Черновик',
            pending: 'В обработке',
            approved: 'Одобрено',
            rejected: 'Отклонено',
            cancelled: 'Отменено',
        };
        return statusMap[status] || status;
    };

    const handleDownloadPdf = () => {
        const link = document.createElement('a');
        link.href = `${process.env.PUBLIC_URL}/input.pdf`;
        link.download = 'input.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <Container>
            <div className={styles.container}>
                <h1>Подтверждение заявки № {bid.bidNumber}</h1>
                <div>Проверьте данные заявки:</div>
                {bid && (
                    <div className={styles.content}>
                        <div className={styles.section}>
                            <h2>Основная информация</h2>
                            <div className={styles.grid}>
                                <div className={styles.field}>
                                    <div className={styles.fieldName}>Статус:</div>
                                    <div className={styles.fieldData}>{getStatusText(bid.status)}</div>
                                </div>
                                <div className={styles.field}>
                                    <div className={styles.fieldName}>Дата создания:</div>
                                    <div className={styles.fieldData}>{formatDate(bid.createdAt)}</div>
                                </div>
                                <div className={styles.field}>
                                    <div className={styles.fieldName}>Запрошенная сумма:</div>
                                    <div className={styles.fieldData}>{bid.requestedAmount?.toLocaleString()} ₽</div>
                                </div>
                                {bid.amount && (
                                    <div className={styles.field}>
                                        <div className={styles.fieldName}>Одобренная сумма:</div>
                                        <div className={styles.fieldData}>{bid.amount?.toLocaleString()} ₽</div>
                                    </div>
                                )}
                                {bid.rate && (
                                    <div className={styles.field}>
                                        <div className={styles.fieldName}>Процентная ставка:</div>
                                        <div className={styles.fieldData}>{bid.rate}%</div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className={styles.section}>
                            <h2>Персональные данные</h2>
                            <div className={styles.grid}>
                                <div className={styles.field}>
                                    <div className={styles.fieldName}>ФИО:</div>
                                    <div className={styles.fieldData}>{bid.personalData?.name}</div>
                                </div>
                                <div className={styles.field}>
                                    <div className={styles.fieldName}>Дата рождения:</div>
                                    <div className={styles.fieldData}>{formatDate(bid.personalData?.birthDate)}</div>
                                </div>
                                <div className={styles.field}>
                                    <div className={styles.fieldName}>Место рождения:</div>
                                    <div className={styles.fieldData}>{bid.personalData?.birthPlace}</div>
                                </div>
                                <div className={styles.field}>
                                    <div className={styles.fieldName}>Пол:</div>
                                    <div className={styles.fieldData}>{bid.personalData?.gender === 'male' ? 'Мужской' : 'Женский'}</div>
                                </div>
                                <div className={styles.field}>
                                    <div className={styles.fieldName}>Телефон:</div>
                                    <div className={styles.fieldData}>{formatPhoneNumber(bid.personalData?.phone)}</div>
                                </div>
                                <div className={styles.field}>
                                    <div className={styles.fieldName}>Email:</div>
                                    <div className={styles.fieldData}>{bid.personalData?.email}</div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.section}>
                            <h2>Паспортные данные</h2>
                            <div className={styles.grid}>
                                <div className={styles.field}>
                                    <div className={styles.fieldName}>Серия и номер:</div>
                                    <div className={styles.fieldData}>{bid.personalData?.passportNumber}</div>
                                </div>
                                <div className={styles.field}>
                                    <div className={styles.fieldName}>Дата выдачи:</div>
                                    <div className={styles.fieldData}>{formatDate(bid.personalData?.issueDate)}</div>
                                </div>
                                <div className={styles.field}>
                                    <div className={styles.fieldName}>Код подразделения:</div>
                                    <div className={styles.fieldData}>{bid.personalData?.departmentCode}</div>
                                </div>
                                <div className={styles.field}>
                                    <div className={styles.fieldName}>Кем выдан:</div>
                                    <div className={styles.fieldData}>{bid.personalData?.issuedBy}</div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.section}>
                            <h2>Адреса</h2>
                            <div className={styles.grid}>
                                <div className={styles.field}>
                                    <div className={styles.fieldName}>Тип регистрации:</div>
                                    <div className={styles.fieldData}>
                                        {bid.personalData?.registrationType === 'permanent'
                                            ? 'Постоянная'
                                            : 'Временная'}
                                    </div>
                                </div>
                                <div className={styles.field}>
                                    <div className={styles.fieldName}>Адрес регистрации:</div>
                                    <div className={styles.fieldData}>{bid.personalData?.registrationAddress}</div>
                                </div>
                                <div className={styles.field}>
                                    <div className={styles.fieldName}>Адрес проживания:</div>
                                    <div className={styles.fieldData}>{bid.personalData?.actualAddress}</div>
                                </div>
                            </div>
                        </div>

                        {bid.additionalData && (
                            <div className={styles.section}>
                                <h2>Дополнительная информация</h2>
                                <div className={styles.grid}>
                                    <div className={styles.field}>
                                        <div className={styles.fieldName}>Образование:</div>
                                        <div className={styles.fieldData}>{bid.additionalData.education}</div>
                                    </div>
                                    <div className={styles.field}>
                                        <div className={styles.fieldName}>Семейное положение:</div>
                                        <div className={styles.fieldData}>{bid.additionalData.maritalStatus}</div>
                                    </div>
                                    <div className={styles.field}>
                                        <div className={styles.fieldName}>Место работы:</div>
                                        <div className={styles.fieldData}>{bid.additionalData.employerName}</div>
                                    </div>
                                    <div className={styles.field}>
                                        <div className={styles.fieldName}>Тип дохода:</div>
                                        <div className={styles.fieldData}>{bid.additionalData.incomeType}</div>
                                    </div>
                                    <div className={styles.field}>
                                        <div className={styles.fieldName}>Доход:</div>
                                        <div className={styles.fieldData}>{bid.additionalData.income?.toLocaleString()} ₽</div>
                                    </div>
                                    {bid.additionalData.tin && (
                                        <div className={styles.field}>
                                            <div className={styles.fieldName}>ИНН работодателя:</div>
                                            <div className={styles.fieldData}>{bid.additionalData.tin}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}

                        <div className={styles.section}>
                            <h2>Выбранный продукт</h2>
                            <div className={styles.grid}>
                                <div className={styles.field}>
                                    <div className={styles.fieldName}>Банк:</div>
                                    <div className={styles.fieldData}>{bid.offer.name}</div>
                                </div>
                                <div className={styles.field}>
                                    <div className={styles.fieldName}>Продукт:</div>
                                    <div className={styles.fieldData}>{bid.offer.category}</div>
                                </div>
                            </div>
                        </div>

                        {/* <div className={styles.section}>
                            <h2>Документы</h2>
                            <ButtonUI label="Скачать PDF для подписания" onClick={handleDownloadPdf} variant="main2" fullWidth />

                        </div> */}
                    </div>
                )}

                <h2 className={styles.approveText}>Сообщая код агенту Вы соглашаетесь с {' '}
                    <span>
                        <a href={`${process.env.PUBLIC_URL}/input.pdf`} target='blank'>
                            Условиями обработки персональных данных.
                        </a>
                    </span></h2>
            </div>
        </Container>
    );
};
