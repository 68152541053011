import React, { useEffect, useState, useRef } from 'react';
import {
    useIssueCardMutation,
    useGetBidStatusQuery,
    useCompleteBidMutation,
    useGetBidCompletedQuery
} from 'services/api';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { useNavigate } from 'react-router-dom';
// import Loader from 'components/Loader/Loader';
import ClockIcon from 'assets/icons/clock.svg';
import ErrorIcon from 'assets/icons/error-icon.svg';
import CheckedIcon from 'assets/icons/checked.svg';
import styles from './Step10.module.scss';
import ButtonUI from 'components/ui/ButtonUI/ButtonUI';

const POLLING_INTERVAL = 3000; // 3 секунды
const MAX_POLLING_ATTEMPTS = 20; // Максимальное количество попыток

interface Step10Props {
    requestId: string | null;
    bidId?: string;
    onStatusChange?: (status: number | undefined) => void;
    setIsFormValid: (isValid: boolean) => void;
}

const Step10: React.FC<Step10Props> = ({ requestId, bidId, onStatusChange, setIsFormValid }) => {
    const [issueCard] = useIssueCardMutation();
    const [completeBid] = useCompleteBidMutation();
    const navigate = useNavigate();
    const [isPolling, setIsPolling] = useState(false);
    const [pollingCount, setPollingCount] = useState(0);
    const [statusMessage, setStatusMessage] = useState<string>('Карта скоро будет готова для выдачи клиенту');
    const [messageType, setMessageType] = useState<'success' | 'error'>('error');
    const [showConfirmButton, setShowConfirmButton] = useState(false);
    const [isCardDelivered, setIsCardDelivered] = useState(false);
    console.log("🚀 ~ file: Step10.tsx:36 ~ isCardDelivered:", isCardDelivered)
    const prevPartnerResponseCode = useRef<number | null>(null);

    const { data: statusData } = useGetBidStatusQuery(
        requestId ? { id: requestId, type: 'issue' } : skipToken,
        {
            pollingInterval: isPolling ? POLLING_INTERVAL : 0,
            refetchOnMountOrArgChange: true
        }
    );

    const { data: completedData } = useGetBidCompletedQuery(
        bidId || skipToken,
        {
            refetchOnMountOrArgChange: true
        }
    );

    const currentStatus = statusData?.message?.partnerResponseCode;

    useEffect(() => {
        const checkAndIssueCard = async () => {
            if (!requestId) return;

            if (currentStatus === -1) {
                try {
                    setIsPolling(true);
                    await issueCard(requestId).unwrap();
                    setStatusMessage('Запрос на выдачу карты отправлен');
                    setMessageType('success');
                } catch (error) {
                    setStatusMessage('Ошибка при отправке запроса на выдачу карты');
                    setIsPolling(false);
                }
            }
        };

        checkAndIssueCard();
    }, [requestId, issueCard, currentStatus]);

    useEffect(() => {
        if (!statusData) return;

        const { partnerResponseCode } = statusData.message || {};

        if (prevPartnerResponseCode.current !== partnerResponseCode && partnerResponseCode !== -1) {
            setPollingCount(prev => prev + 1);
            prevPartnerResponseCode.current = partnerResponseCode;
        }

        if (partnerResponseCode !== undefined) {
            onStatusChange?.(partnerResponseCode);

            switch (partnerResponseCode) {
                case -1:
                    setStatusMessage('В ожидании подписания документов от Банка');
                    setMessageType('error');
                    break;
                case 0:
                    setIsPolling(false);
                    setStatusMessage('Карта готова для выдачи клиенту');
                    setMessageType('success');
                    setIsFormValid(true);
                    setShowConfirmButton(true);
                    break;
                default:
                    setIsPolling(false);
                    setStatusMessage('Произошла ошибка при выдаче карты');
                    setMessageType('error');
                    break;
            }
        }

        if (pollingCount >= MAX_POLLING_ATTEMPTS) {
            setIsPolling(false);
            setStatusMessage('Превышено максимальное количество попыток');
            setMessageType('error');
        }
    }, [statusData, onStatusChange, pollingCount, setIsFormValid]);

    useEffect(() => {
        if (completedData?.status) {
            setIsCardDelivered(true);
        }
    }, [completedData]);

    const handleComplete = async () => {
        if (!bidId) {
            console.error('ID заявки не найден');
            return;
        }

        try {
            await completeBid(bidId).unwrap();
            setIsCardDelivered(true);
            navigate('/main');
        } catch (error) {
            console.error('Ошибка при завершении заявки:', error);
        }
    };

    return (
        <div className={styles.cardWrapper}>
            <div className={`${styles.card} ${messageType === 'success' ? styles.successBorder : ''}`}>
                <h3 className={styles.title}>{statusMessage}</h3>
                <div>Статус:</div>
                {messageType === 'error' && (
                    <div className={styles.statusWrapper}>
                        <img
                            src={ErrorIcon}
                            alt="Ошибка"
                            className={`${styles.statusIcon} ${isPolling ? styles.rotating : ''}`}
                        />
                        <span className={styles.statusText}>{statusMessage}</span>
                    </div>
                )}
                {messageType === 'success' && showConfirmButton && (
                    <div className={styles.confirmSection}>
                        <img
                            src={CheckedIcon}
                            alt="Успешно"
                            className={styles.statusIcon}
                        />
                        <p>Одобрено</p>
                    </div>
                )}
            </div>
            {messageType === 'success' && showConfirmButton && (
                <div className={styles.confirmButtonWrapper}>
                    <p className={styles.confirmText}>Документ с банком подписан</p>
                    <div className={styles.confirmDescription}>
                        {isCardDelivered ? 'Карта передана клиенту' : 'Подтвердите передачу карты клиенту'}
                    </div>
                    {!isCardDelivered && (
                        <ButtonUI
                            label="Подтвердить"
                            variant="main"
                            height='64px'
                            width='275px'
                            onClick={handleComplete}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default Step10;
