import React, { useEffect, useState } from 'react';
import { PepConsentPhotoForm } from 'components/PepConsentPhotoForm';
import { useGetCardRequestByIdQuery, useGetBidStatusQuery } from 'services/api';
import { skipToken } from '@reduxjs/toolkit/query/react';

interface Step8Props {
    setIsFormValid: (isValid: boolean) => void;
    onFormSubmit: (data: any) => void;
    requestId: string | null;
    onStatusChange?: (status: number | undefined) => void;
}

const Step8: React.FC<Step8Props> = ({ setIsFormValid, onFormSubmit, requestId, onStatusChange }) => {
    const { data: cardRequestData } = useGetCardRequestByIdQuery(requestId);
    const { data: checkCardStatus } = useGetBidStatusQuery(
        requestId ? { id: requestId, type: 'check_card' } : skipToken
    );

    // Не делаем поллинг generate_pep если check_card вернул код 6
    const skipGeneratePep = checkCardStatus?.message?.partnerResponseCode === 6;
    const [statusMessage, setStatusMessage] = useState<string>('');
    const [messageType, setMessageType] = useState<'success' | 'error'>('error');
    const [isFormEnabled, setIsFormEnabled] = useState(false);
    console.log("🚀 ~ file: Step8.tsx:24 ~ isFormEnabled:", isFormEnabled)

    // Проверяем текущий статус и сразу передаем его в родительский компонент
    useEffect(() => {
        if (skipGeneratePep && checkCardStatus?.message?.partnerResponseCode !== undefined) {
            onStatusChange?.(checkCardStatus.message.partnerResponseCode);
        }
    }, [skipGeneratePep, checkCardStatus, onStatusChange]);

    // При монтировании компонента проверяем текущий статус
    useEffect(() => {
        if (!requestId) return;

        // Если partnerResponseCode === 6, не вызываем generate-pep
        if (skipGeneratePep) {
            setStatusMessage('Подписание ПЭП не требуется. Ожидайте подписания документов клиентом.');
            setMessageType('success');
            setIsFormValid(true);
            return;
        }
    }, [requestId, skipGeneratePep, setIsFormValid]);

    // Обработка статуса колбека
    useEffect(() => {
        // Если не нужен ПЭП, сразу устанавливаем сообщение
        if (skipGeneratePep) {
            setStatusMessage('Подписание ПЭП не требуется. Ожидайте подписания документов клиентом.');
            setMessageType('success');
            setIsFormEnabled(false);
            setIsFormValid(true);
            return;
        }

        if (checkCardStatus?.message?.partnerResponseCode === 0) {
            setIsFormEnabled(true);
        }
    }, [skipGeneratePep, checkCardStatus?.message?.partnerResponseCode, setIsFormValid]);

    // Обработчик успешной загрузки фото
    const handlePhotoUploadSuccess = () => {
        setStatusMessage('Фото успешно загружены');
        setMessageType('success');
    };

    if (!requestId) {
        return null;
    }

    return (
        <PepConsentPhotoForm
            requestId={requestId}
            setIsFormValid={setIsFormValid}
            onUploadSuccess={handlePhotoUploadSuccess}
            serverData={cardRequestData}
            disabled={!isFormEnabled}
            messageType={messageType}
            statusMessage={statusMessage}
            statusCode={checkCardStatus?.message?.partnerResponseCode}
        />
    );
};

export default Step8;
