import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { TextUI } from 'components/ui/TextUI/ui/TextUI';
import { CardWrapper } from 'components/CardWrapper/CardWrapper';
import LoadingIcon from 'assets/icons/loading-icon.svg';
import GreenCheckIcon from 'assets/icons/green-check.svg';
import ErrorIcon from 'assets/icons/error-icon.svg';
import { Tooltip } from 'components/ui/Tooltip';
import ButtonUI from 'components/ui/ButtonUI/ButtonUI';
import InfoIcon from 'assets/icons/info.svg';

import styles from './EanCardCheckForm.module.scss';

const schema = yup.object().shape({
    ean: yup
        .string()
        .required('Поле обязательно для заполнения')
        .matches(/^\d+$/, 'Только цифры')
        .length(13, 'EAN должен содержать ровно 13 цифр')
});

interface EanCardCheckFormProps {
    onSubmit: (data: any) => void;
    onReset?: () => void;
    isLoading?: boolean;
    isPolling?: boolean;
    hasSubmitted?: boolean;
    errorMessage?: string | null;
    statusMessage?: string | null;
    isSuccess?: boolean;
    isMobile?: boolean;
    showResetButton?: boolean;
}

const EanCardCheckForm: React.FC<EanCardCheckFormProps> = ({
    onSubmit,
    onReset,
    isLoading = false,
    isPolling = false,
    hasSubmitted = false,
    errorMessage = null,
    statusMessage = null,
    isSuccess = false,
    isMobile = false,
    showResetButton = false
}) => {
    // console.log("🚀 ~ file: EanCardCheckForm.tsx:43 ~ hasSubmitted:", hasSubmitted)

    const { register, handleSubmit, reset, formState: { errors, isValid } } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange'
    });
    const [lastEanValue, setLastEanValue] = useState<string>('');

    const handleFormSubmit = (data: any) => {
        setLastEanValue(data.ean);
        onSubmit(data);
    };

    const handleReset = () => {
        reset();
        if (onReset) onReset();
    };

    const renderForm = () => (
        <form onSubmit={handleSubmit(handleFormSubmit)}>
            <div className={styles.inputWrapper}>
                <input
                    type="text"
                    placeholder="EAN КАРТЫ"
                    {...register('ean')}
                    className={`${styles.input} ${errors.ean ? styles.error : ''}`}
                    disabled={isLoading || (hasSubmitted && !showResetButton)}
                    defaultValue={lastEanValue}
                />
            </div>
            <TextUI variant="bodyS" className={styles.description}>
                Укажите 13-значный код с карты, расположенный под основным номером
            </TextUI>
            {(!hasSubmitted || showResetButton) && (
                <>
                    <ButtonUI 
                        type={showResetButton ? 'button' : 'submit'} 
                        width={isMobile ? 'auto' : '220px'} 
                        label={showResetButton ? 'ОТПРАВИТЬ ЗАНОВО' : 'ОТПРАВИТЬ В БАНК'} 
                        disabled={!isValid} 
                        variant={'main'} 
                        onClick={showResetButton ? handleReset : undefined} 
                    />
                </>
            )}
        </form>
    );

    const renderStatus = () => {
        if (isSuccess) {
            return (
                <div className={`${styles.messageWrapper} ${styles.approvedWrapper}`}>
                    <img
                        src={GreenCheckIcon}
                        alt="Одобрено"
                        className={styles.statusIcon}
                    />
                    <span className={styles.approveText}>
                        {statusMessage || 'EAN прошел проверку'}
                    </span>
                </div>
            );
        }

        if (!hasSubmitted) return null;

        if (isLoading) {
            return (
                <div className={`${styles.loadingRow}`}>
                    <img
                        src={LoadingIcon}
                        alt="Загрузка"
                        className={`${styles.statusIcon} ${styles.rotating}`}
                    />
                    <span className={`${styles.approveText} ${styles.loading}`}>
                        {isPolling ? 'Проверяем статус карты...' : 'Отправка запроса...'}
                    </span>
                </div>
            );
        }

        const displayMessage = errorMessage || statusMessage || 'Произошла ошибка при проверке карты';

        return (
            <div className={`${styles.messageWrapper} ${styles.rejectedWrapper}`}>
                <img
                    src={ErrorIcon}
                    alt="Отказано"
                    className={styles.statusIcon}
                />
                <span className={styles.approveText}>
                    {displayMessage}
                </span>
            </div>
        );
    };

    return (
        <CardWrapper noBorder={isMobile} minWidth="512">
            <div className={styles.titleWrapper}>
                <TextUI variant="h2" fullWidth={false}>Проверка EAN карты</TextUI>
                <Tooltip
                    maxWidth="310px"
                    content={
                        <div className={styles.tooltipWrapper}>
                            Укажите 13-значный код с карты, расположенный под основным номером
                        </div>
                    }
                >
                    <img src={InfoIcon} alt="info" className={styles.infoIcon} />
                </Tooltip>
            </div>
            {!isSuccess && renderForm()}
            {renderStatus()}
        </CardWrapper>
    );
};

export default EanCardCheckForm;
