import React from 'react';

const ArrowRightWhite = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none" style={{ transform: 'rotate(180deg)' }}>
      <g clipPath="url(#clip0_381_14058)">
        <path fillRule="evenodd" clipRule="evenodd" d="M8.01956 19.6723L1.11466 12.7674C0.690945 12.3436 0.690945 11.6567 1.11466 11.2329L8.01956 4.32804C8.44328 3.90432 9.13026 3.90432 9.55398 4.32804C9.9777 4.75176 9.9777 5.43875 9.55398 5.86246L4.5013 10.9152H23.0019C23.6011 10.9152 24.0869 11.4009 24.0869 12.0002C24.0869 12.5994 23.6011 13.0852 23.0019 13.0852H4.5013L9.55398 18.1378C9.9777 18.5616 9.9777 19.2485 9.55398 19.6723C9.13026 20.096 8.44328 20.096 8.01956 19.6723Z" fill="#cccccc" />
      </g>
      <defs>
        <clipPath id="clip0_381_14058">
          <rect width="24" height="24" fill="white" transform="matrix(-1 0 0 -1 24 24.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ArrowRightWhite;
