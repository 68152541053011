import React, { useEffect } from 'react';
import { SliderSwitch } from 'components/SliderSwitch';
import Select from 'react-select';
import { useMediaQuery } from 'shared/hooks/UseMediaQuery';
import { useGetCardRequestByIdQuery } from '../../services/api';
import EducationIcon from 'assets/icons/education.svg';

import styles from './ProgressSteps.module.scss';

interface ProgressStepsProps {
  currentStep: number;
  totalSteps: number;
  onStepClick: (step: number) => void;
  requestId: string | null;
}

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    height: '42px',
    borderRadius: '21px',
    border: '2px solid #ccc',
    boxShadow: 'none',
    '&:hover': {
      borderColor: 'black',
    },
    color: 'black',
    fontWeight: '600',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    paddingLeft: '0px',
  }),
};

const ProgressSteps: React.FC<ProgressStepsProps> = ({
  currentStep,
  totalSteps,
  onStepClick,
  requestId
}) => {
  const isMobile = useMediaQuery(1250);
  const { data: bid, refetch } = useGetCardRequestByIdQuery(requestId, {
    skip: !requestId
  });

  const stepsOptions = Array.from({ length: totalSteps }, (_, i) => ({
    value: i + 1,
    label: `Шаг ${i + 1}`,
  }));

  const approvalProbability = Math.min(10 + (currentStep - 1) * 10, 100);
  // console.log("🚀 ~ file: ProgressSteps.tsx:56 ~ approvalProbability:", approvalProbability)

  return (
    <>
      <div className={styles.progressSteps}>
        {requestId && bid && (
          <>
            <SliderSwitch
              currentStep={currentStep}
              bidId={requestId}
              initialSandbox={bid.sandbox}
              onChange={async () => {
                await refetch();
              }}
            />

          </>
        )}
        {currentStep !== 0 && (isMobile ? (
          <Select
            options={stepsOptions}
            value={stepsOptions.find((option) => option.value === currentStep)}
            onChange={(selectedOption) => {
              if (selectedOption) {
                onStepClick(selectedOption.value);
              }
            }}
            className={styles.mobileSelect}
            classNamePrefix="select"
            placeholder={`Шаг ${currentStep}`}
            styles={customStyles}
          />
        ) : (
          <>
            {stepsOptions.map((stepOption, index) => (
              <React.Fragment key={stepOption.value}>
                <div
                  className={`${styles.step} ${stepOption.value === currentStep ? styles.activeStep : ''}`}
                  onClick={() => onStepClick(stepOption.value)}
                >
                  <span>{stepOption.label}</span>
                </div>
                {index < stepsOptions.length - 1 && (
                  <div className={styles.stepDivider}>
                    - &mdash; -
                  </div>
                )}
              </React.Fragment>
            ))}
          </>
        ))}
      </div>

      {bid?.sandbox === true && (
        <div className={styles.educationBlock}>
          <img src={EducationIcon} alt="educationIcon" />
          <span>Тестовый режим</span>
        </div>
      )}
      <div className={styles.approvalIndicator}>
        <div className={styles.approvalText}>
          Вероятность одобрения: <span className={styles.probabilityValue}>+{approvalProbability}%</span>
        </div>
        <div className={styles.progressBarContainer}>
          <div className={styles.progressBar}>
            <div
              className={styles.progressFill}
              style={{ width: `${approvalProbability}%` }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProgressSteps;
