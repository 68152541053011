import React, { useMemo, useState, useEffect } from 'react';
import MainAppLayout from 'app/layouts/MainAppLayout/MainAppLayout';
import { TextUI } from 'components/ui/TextUI/ui/TextUI';
import Table from 'components/ui/Table/ui/Table';
import CustomCalendarSelect from 'components/ui/CustomCalendarSelect/ui/CustomCalendarSelect';
import { SingleSelectSearch } from 'components/ui/SingleSelectSearch/ui/SingleSelectSearch';
import ButtonUI from 'components/ui/ButtonUI/ButtonUI';
import { OptionType } from 'components/Offers/model/types';
import { useMediaQuery } from 'shared/hooks/UseMediaQuery'; // Хук для мобильной версии
import FiltersIcon from 'assets/icons/FiltersIcon'; // Иконка фильтров
import { MobileFilter } from 'components/ui/MobileFilter'; // Добавляем MobileFilter
import { useGetApplicationsByUserIdQuery, useGetApplicationsQuery } from 'services/api';

import styles from './StatisticByOffers.module.scss';
import { useSelector } from 'react-redux';

// const getDefaultDateRange = (): [Date, Date] => {
//   // const endDate = new Date();
//   // const startDate = new Date();
//   // startDate.setMonth(startDate.getMonth() - 2);  // Два месяца назад
//   // return [startDate, endDate];
//   const today = new Date();
//   // Set the start date to January 1st of the current year
//   const startOfYear = new Date(today.getFullYear(), 0, 1);
//   return [startOfYear, today];
// };

const getDefaultDateRange = (): [Date, Date] => {
  const today = new Date();
  const sixMonthsAgo = new Date();
  sixMonthsAgo.setMonth(today.getMonth() - 6);

  // Если текущий месяц - январь, нужно скорректировать год при вычислении
  if (sixMonthsAgo.getMonth() > today.getMonth()) {
    sixMonthsAgo.setFullYear(today.getFullYear() - 1);
  }

  return [sixMonthsAgo, today];
};

const StatisticByOffers = () => {
  const [dateRange, setDateRange] = useState<[Date, Date]>(getDefaultDateRange());
  const [selectedOffer, setSelectedOffer] = useState<string | null>(null);
  const [resetSelect, setResetSelect] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // Состояние для мобильного модального окна
  const [resetDates, setResetDates] = useState<boolean>(false);
  const isMobile = useMediaQuery(768); // Определяем мобильную версию

  const userId = useSelector((state: any) => state.login?.user?._id);
  const { data: applications = [], error, isLoading } = useGetApplicationsByUserIdQuery(userId); //useGetApplicationsQuery({ page: 1 });

  // Transform applications data into the required format
  const transformedData = useMemo(() => {
    if (!applications.length) return [];

    // Group applications by bank and product
    const stats = applications.reduce((acc: any[], app: any) => {
      const existingEntry = acc.find(
        entry =>
          entry.offerName === app.offer.name &&
          entry.product === app.offer.category
      );

      if (existingEntry) {
        existingEntry.all += 1;
        switch (app.status) {
          case 'approved':
            existingEntry.approved += 1;
            break;
          case 'draft':
            existingEntry.inProgress += 1;
            break;
          case 'rejected':
            existingEntry.rejected += 1;
            break;
        }
      } else {
        // Create new entry
        acc.push({
          offerId: app.offer.id, // Используем ID из моковых данных
          offerName: app.offer.name,
          product: app.offer.category,
          all: 1,
          approved: app.status === 'approved' ? 1 : 0,
          inProgress: app.status === 'draft' ? 1 : 0,
          rejected: app.status === 'rejected' ? 1 : 0,
          date: app.date
        });
      }

      return acc;
    }, []);

    return stats;
  }, [applications]);

  const [filteredData, setFilteredData] = useState(transformedData);

  useEffect(() => {
    setFilteredData(transformedData);
  }, [transformedData]);

  const offerOptions = useMemo(() => {
    const uniqueBanks = Array.from(new Set(transformedData.map(offer => offer.offerName)));
    return uniqueBanks.map(bank => ({
      label: bank,
      value: bank
    }));
  }, [transformedData]);

  const columns = useMemo(() => [
    {
      accessorKey: 'offerId',
      header: 'Оффер ID',
    },
    {
      accessorKey: 'offerName',
      header: 'Оффер',
      cell: (info: any) => (
        <div>
          <strong>{info.getValue()}</strong>
          <div>{info.row.original.product}</div>
        </div>
      ),
      className: styles.offerColumn, // Добавляем класс для стилизации столбца "Оффер"
    },
    {
      accessorKey: 'all',
      header: 'Все',
      className: styles.withSeparator, // Добавляем класс для разделительной линии
    },
    {
      accessorKey: 'approved',
      header: 'Принято',
    },
    {
      accessorKey: 'inProgress',
      header: 'В работе',
    },
    {
      accessorKey: 'rejected',
      header: 'Отклонено',
    },
  ], []);

  useEffect(() => {
    let filtered = transformedData;

    if (dateRange) {
      const [startDate, endDate] = dateRange;
      filtered = filtered.filter((item: any) => {
        const itemDate = new Date(item.date);
        return itemDate >= startDate && itemDate <= endDate;
      });
    }

    if (selectedOffer) {
      filtered = filtered.filter((item: any) => item.offerName === selectedOffer);
    }

    setFilteredData(filtered);
  }, [dateRange, selectedOffer, transformedData]);

  const handleReset = () => {
    // const today = new Date();
    // const startOfYear = new Date(today.getFullYear(), 0, 1);

    setSelectedOffer(null);
    setResetSelect(true);
    setDateRange(getDefaultDateRange());
    setFilteredData(transformedData);
    localStorage.setItem('dateRange', JSON.stringify(getDefaultDateRange()));
    setTimeout(() => setResetDates(false), 100);
  };

  const handleDateChange = (range: [Date, Date]) => {
    setDateRange(range);
    localStorage.setItem('dateRange', JSON.stringify(range)); // Сохраняем диапазон в LocalStorage
  };

  const handleOfferSelect = (selected: OptionType | null) => {
    if (selected) {
      const selectedValue = typeof selected.value === 'string' ? selected.value : String(selected.value);
      setSelectedOffer(selectedValue);
    } else {
      setSelectedOffer(null);
    }
    setResetSelect(false);
  };

  return (
    <MainAppLayout>
      <TextUI variant="h1" className={styles.marginBottom}>Статистика по офферам</TextUI>

      {isMobile ? (
        <>
          {/* Иконка для открытия фильтров на мобильной версии */}
          <div className={styles.filterMenu} onClick={() => setIsModalOpen(true)}>
            <FiltersIcon />
          </div>

          <div className={styles.twoFilters}>
            <SingleSelectSearch
              options={offerOptions}
              onOptionSelect={handleOfferSelect}
              placeholder="Поиск по офферу"
              reset={resetSelect}
            />
          </div>

          {/* Модальное окно фильтров для мобильной версии */}
          {isModalOpen && (
            <MobileFilter
              title="Параметры"
              onClose={() => setIsModalOpen(false)}
              onShow={() => setIsModalOpen(false)}
              onReset={handleReset}
              filterBtnClassName={styles.offerBtnMobileFilter}
            >
              <CustomCalendarSelect onDateChange={handleDateChange} resetDates={resetDates} dateRange={dateRange} />
            </MobileFilter>
          )}
        </>
      ) : (
        <div className={styles.filters}>
          <div className={styles.twoFilters}>
            <SingleSelectSearch
              options={offerOptions}
              onOptionSelect={handleOfferSelect}
              placeholder="Поиск по офферу"
              reset={resetSelect}
            />
            <CustomCalendarSelect
              onDateChange={handleDateChange}
              resetDates={resetDates}
              dateRange={dateRange}
              title="Дата заявки"
            />
          </div>
          <div className={styles.filterButtons}>
            {/* <ButtonUI label="Показать" variant="main" height='42px' onClick={() => { }} /> */}
            <ButtonUI label="Сбросить фильтр" variant="main2" height='42px' onClick={handleReset} />
          </div>
        </div>
      )}

      <div className={styles.statistics}>
        <Table columns={columns} data={filteredData} showTitleRow tablePageSize={Number(7)} />
      </div>
    </MainAppLayout>
  );
};

export default StatisticByOffers;
