export const MAX_FILE_SIZE = 100 * 1024 * 1024; // 100MB
export const OPTIMAL_FILE_SIZE = 10 * 1024 * 1024; // 10MB

export const formatFileSize = (size: number) => {
    if (size < 1024) return `${size} B`;
    if (size < 1048576) return `${(size / 1024).toFixed(1)} KB`;
    return `${(size / 1048576).toFixed(1)} MB`;
};

export const validateFileType = (file: File) => {
    const validTypes = ['image/jpeg', 'image/png', 'application/pdf'];
    return validTypes.includes(file.type);
};

export const getFileExtension = (file: File): string => {
    return file.name.split('.').pop()?.toLowerCase() || '';
};

// export const normalizePath = (path: string) => path.replace(/\\/g, '/');
export const normalizePath = (path: string) => {
    // Заменяем обратные слэши на прямые
    let normalizedPath = path.replace(/\\/g, '/');

    // Добавляем 'server/' в путь, если он отсутствует
    if (!normalizedPath.includes('server/')) {
        normalizedPath = normalizedPath.replace('uploads', 'server/uploads');
    }

    return normalizedPath;
};

export const acceptedFileTypes = {
    'image/jpeg': ['.jpg', '.jpeg'],
    'image/png': ['.png'],
    'application/pdf': ['.pdf']
};

export type FileType = 'DUL1' | 'DUL2' | 'DUL' | 'PDN' | 'PHOTO' | 'PEP' | 'BLANKPEP';

export const formatFileName = (requestId: string, type: FileType, originalFile: File): string => {
    const extension = getFileExtension(originalFile).toLowerCase();
    return `${requestId}_${type}.${extension}`;
};

export const createFileWithNewName = (originalFile: File, newName: string): File => {
    return new File([originalFile], newName, { type: originalFile.type });
};
