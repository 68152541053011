import React, { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { CustomInput } from 'components/CustomInput/CustomInput';
import { InputTypeEnum } from 'shared/models';
import { TextUI } from 'components/ui/TextUI/ui/TextUI';
import { RadioButton } from 'components/Forms/RadioButton';
import { CardWrapper } from 'components/CardWrapper/CardWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { savePersonalData } from 'features/request/requestSlice';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { PersonalData } from 'shared/types/personalData';
import { useUpdateRequestStepMutation } from 'services/api';

import styles from './PersonalDataForm.module.scss';

interface PersonalDataFormProps {
  setIsFormValid: (isValid: boolean) => void;
  onFormSubmit: (data: PersonalData) => void;
  defaultValues?: Partial<PersonalData>;
  requestId?: string | null;
}

const validationSchema = yup.object({
  surname: yup.string()
    .required('Поле обязательно для заполнения')
    .matches(/^[А-Яа-яЁё]+$/, 'Фамилия должна содержать только русские буквы')
    .min(2, 'Фамилия должна быть не менее 2 символов')
    .max(50, 'Фамилия должна быть не более 50 символов'),
  name: yup.string()
    .required('Поле обязательно для заполнения')
    .matches(/^[А-Яа-яЁё]+$/, 'Имя должно содержать только русские буквы')
    .min(2, 'Имя должно быть не менее 2 символов')
    .max(50, 'Имя должно быть не более 50 символов'),
  patronymic: yup.string()
    .transform((value) => (!value ? null : value))
    .nullable()
    .test('russian-letters', 'Отчество должно содержать только русские буквы', (value) => {
      if (!value) return true;
      return /^[А-Яа-яЁё]+$/.test(value);
    })
    .max(50, 'Отчество должно быть не более 50 символов'),
  gender: yup.string()
    .required('Поле обязательно для заполнения')
    .oneOf(['male', 'female'] as const, 'Выберите пол'),
  birthDate: yup.string()
    .required('Поле обязательно для заполнения')
    .test('valid-date', 'Некорректная дата', (value) => {
      if (!value) return false;
      const date = new Date(value);
      return date instanceof Date && !isNaN(date.getTime());
    })
    .test('min-age', 'Возраст должен быть не менее 18 лет', (value) => {
      if (!value) return false;
      const date = new Date(value);
      const today = new Date();
      const age = today.getFullYear() - date.getFullYear();
      const monthDiff = today.getMonth() - date.getMonth();
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < date.getDate())) {
        return age - 1 >= 18;
      }
      return age >= 18;
    })
    .test('max-age', 'Возраст не может превышать 100 лет', (value) => {
      if (!value) return false;
      const date = new Date(value);
      const today = new Date();
      const age = today.getFullYear() - date.getFullYear();
      return age <= 100;
    }),
  email: yup.string()
    .required('Поле обязательно для заполнения')
    .email('Некорректный email')
    .max(100, 'Email должен быть не более 100 символов'),
  phone: yup.string()
    .required('Поле обязательно для заполнения')
    .matches(/^\+?[0-9]{11}$/, 'Некорректный формат телефона'),
  passportNumber: yup.string()
    .required('Поле обязательно для заполнения')
    .matches(/^\d{4}\s\d{6}$/, 'Формат: 4 цифры, пробел, 6 цифр')
    .test('space-after-fourth', 'Добавьте пробел после первых 4 цифр', (value) => {
      if (!value) return true; // пропускаем, если поле пустое (required проверит это)
      if (value.length > 4 && value[4] !== ' ') {
        return false;
      }
      return true;
    }),
  birthPlace: yup.string()
    .required('Поле обязательно для заполнения')
    .min(2, 'Место рождения должно быть не менее 2 символов')
    .max(200, 'Место рождения должно быть не более 200 символов'),
  issueDate: yup.string()
    .required('Поле обязательно для заполнения')
    .test('valid-date', 'Некорректная дата', (value) => {
      if (!value) return false;
      const date = new Date(value);
      return date instanceof Date && !isNaN(date.getTime());
    })
    .test('not-future', 'Дата выдачи не может быть в будущем', (value) => {
      if (!value) return false;
      const date = new Date(value);
      const today = new Date();
      return date <= today;
    })
    // .test('not-too-old', 'Паспорт должен быть выдан не более 20 лет назад', (value) => {
    //   if (!value) return false;
    //   const date = new Date(value);
    //   const today = new Date();
    //   const yearDiff = today.getFullYear() - date.getFullYear();
    //   return yearDiff <= 20;
    // })
    .test('after-14-years', 'Паспорт не может быть выдан ранее 14-летия', function (value) {
      if (!value) return false;
      const issueDate = new Date(value);
      const birthDate = new Date(this.parent.birthDate);
      const age = issueDate.getFullYear() - birthDate.getFullYear();
      const monthDiff = issueDate.getMonth() - birthDate.getMonth();
      if (monthDiff < 0 || (monthDiff === 0 && issueDate.getDate() < birthDate.getDate())) {
        return age - 1 >= 14;
      }
      return age >= 14;
    }),
  departmentCode: yup.string()
    .required('Поле обязательно для заполнения')
    .matches(/^\d{3}-\d{3}$/, 'Некорректный формат кода подразделения'),
  issuedBy: yup.string()
    .required('Поле обязательно для заполнения')
    .min(2, 'Поле должно быть не менее 2 символов')
    .max(200, 'Поле должно быть не более 200 символов'),
  registrationAddress: yup.string()
    .required('Поле обязательно для заполнения')
    .min(5, 'Адрес должен быть не менее 5 символов')
    .max(200, 'Адрес должен быть не более 200 символов'),
  actualAddress: yup.string()
    .when('addressMatches', {
      is: 'no',
      then: (schema) => schema
        .required('Поле обязательно для заполнения')
        .min(5, 'Адрес должен быть не менее 5 символов')
        .max(200, 'Адрес должен быть не более 200 символов')
    }),
  registrationType: yup.string()
    .required('Поле обязательно для заполнения')
    .oneOf(['permanent', 'temporary'] as const, 'Выберите тип регистрации'),
  addressMatches: yup.string()
    .required('Поле обязательно для заполнения')
    .oneOf(['yes', 'no'] as const, 'Выберите значение')
});

export const PersonalDataForm: React.FC<PersonalDataFormProps> = ({
  setIsFormValid,
  onFormSubmit,
  defaultValues,
  requestId,
}) => {
  const dispatch = useDispatch();
  const [updateRequestStep] = useUpdateRequestStepMutation();

  const methods = useForm<PersonalData>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
    defaultValues: {
      surname: defaultValues?.surname || '',
      name: defaultValues?.name || '',
      patronymic: defaultValues?.patronymic || '',
      birthDate: defaultValues?.birthDate || '',
      gender: (defaultValues?.gender as 'male' | 'female') || 'male',
      email: defaultValues?.email || '',
      phone: defaultValues?.phone || '',
      passportNumber: defaultValues?.passportNumber || '',
      birthPlace: defaultValues?.birthPlace || '',
      issueDate: defaultValues?.issueDate || '',
      departmentCode: defaultValues?.departmentCode || '',
      issuedBy: defaultValues?.issuedBy || '',
      registrationType: (defaultValues?.registrationType as 'permanent' | 'temporary') || 'permanent',
      addressMatches: (defaultValues?.addressMatches as 'yes' | 'no') || 'yes',
      registrationAddress: defaultValues?.registrationAddress || '',
      actualAddress: defaultValues?.actualAddress || '',
    },
  });

  const {
    handleSubmit,
    watch,
    formState: { errors, isValid },
    setValue,
  } = methods;
  // console.log("🚀 ~ file: PersonalDataForm.tsx:179 ~ errors:", errors)

  const addressMatches = watch('addressMatches') === 'yes';

  useEffect(() => {
    setIsFormValid(isValid);
  }, [isValid, setIsFormValid]);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      // Если изменилось поле registrationAddress и адреса совпадают
      if (name === 'registrationAddress' && watch('addressMatches') === 'yes') {
        setValue('actualAddress', value.registrationAddress as string);
      }
      // Только сохраняем в Redux, без обновления на сервере
      dispatch(savePersonalData(value));
    });
    return () => subscription.unsubscribe();
  }, [watch, dispatch, setValue]);

  const handleAddressMatchesChange = (value: 'yes' | 'no') => {
    setValue('addressMatches', value);
    if (value === 'yes') {
      const registrationAddress = watch('registrationAddress');
      setValue('actualAddress', registrationAddress || '');
    }
  };

  const onSubmit = (data: any) => {
    onFormSubmit(data);
  };

  return (
    <FormProvider {...methods}>
      <form className={styles.formWrapper} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.flexRow}>
          <CardWrapper title="Персональные данные">
            <CustomInput
              name="surname"
              type={InputTypeEnum.Text}
              placeholder="ФАМИЛИЯ"
              description="Укажите точно как в паспорте"
              validate={(value) => /^[А-Яа-яЁё]+$/.test(value) || 'Фамилия должна содержать только русские буквы'}
              required
              hideIcon
            />
            <CustomInput
              name="name"
              type={InputTypeEnum.Text}
              placeholder="ИМЯ"
              description="Укажите точно как в паспорте"
              validate={(value) => /^[А-Яа-яЁё]+$/.test(value) || 'Имя должно содержать только русские буквы'}
              required
              hideIcon
            />
            <CustomInput
              name="patronymic"
              type={InputTypeEnum.Text}
              placeholder="ОТЧЕСТВО"
              description="Укажите точно как в паспорте"
              validate={(value) => /^[А-Яа-яЁё]+$/.test(value) || 'Отчество должно содержать только русские буквы'}
              hideIcon
            />
            <CustomInput
              name="birthDate"
              type={InputTypeEnum.Date}
              placeholder="ДАТА РОЖДЕНИЯ"
              required
              hideIcon
            />
            <TextUI variant="bodyS" color="secondary">
              ПОЛ
            </TextUI>
            <div className={styles.radioGroup}>
              <RadioButton
                name="gender"
                value="male"
                label="Мужской"
                checked={watch('gender') === 'male'}
                onChange={() => setValue('gender', 'male')}
              />
              <RadioButton
                name="gender"
                value="female"
                label="Женский"
                checked={watch('gender') === 'female'}
                onChange={() => setValue('gender', 'female')}
              />
            </div>
            <CustomInput
              name="email"
              type={InputTypeEnum.Email}
              placeholder="ЭЛЕКТРОННАЯ ПОЧТА"
              required
              hideIcon
            />
            <CustomInput
              name="phone"
              type={InputTypeEnum.Tel}
              placeholder="МОБИЛЬНЫЙ ТЕЛЕФОН"
              required
              hideIcon
            />
          </CardWrapper>

          <div className={styles.column}>
            <TextUI variant="h2">Паспортные данные</TextUI>
            <CustomInput
              name="passportNumber"
              type={InputTypeEnum.Text}
              placeholder="СЕРИЯ И НОМЕР ПАСПОРТА"
              description="1234 567890"
              required
              hideIcon
            />
            <CustomInput
              name="birthPlace"
              type={InputTypeEnum.Text}
              placeholder="МЕСТО РОЖДЕНИЯ"
              description="Укажите точно как в паспорте"
              required
              hideIcon
            />
            <CustomInput
              name="issueDate"
              type={InputTypeEnum.Date}
              placeholder="ДАТА ВЫДАЧИ"
              required
              hideIcon
            />
            <CustomInput
              name="departmentCode"
              type={InputTypeEnum.Text}
              placeholder="КОД ПОДРАЗДЕЛЕНИЯ"
              description="123-456"
              required
              hideIcon
            />
            <CustomInput
              name="issuedBy"
              type={InputTypeEnum.Text}
              placeholder="КЕМ ВЫДАН"
              description="Укажите точно как в паспорте"
              required
              hideIcon
            />
          </div>
        </div>

        <div className={styles.column}>
          <TextUI variant="h2">Адрес регистрации</TextUI>
          <CustomInput
            name="registrationAddress"
            type={InputTypeEnum.Text}
            placeholder="АДРЕС РЕГИСТРАЦИИ"
            description="Страна, город, улица, дом, корпус и квартира"
            required
            hideIcon
          />

          <TextUI color="secondary" variant="bodyS">
            РЕГИСТРАЦИЯ
          </TextUI>
          <div className={styles.radioGroup}>
            <RadioButton
              name="registrationType"
              label="Постоянная"
              value="permanent"
              checked={watch('registrationType') === 'permanent'}
              onChange={() => setValue('registrationType', 'permanent')}
            />
            <RadioButton
              name="registrationType"
              label="Временная"
              value="temporary"
              checked={watch('registrationType') === 'temporary'}
              onChange={() => setValue('registrationType', 'temporary')}
            />
          </div>

          <TextUI color="secondary" variant="bodyS">
            ФАКТИЧЕСКИЙ АДРЕС ПРОЖИВАНИЯ СОВПАДАЕТ С АДРЕСОМ РЕГИСТРАЦИИ?
          </TextUI>
          <div className={styles.radioGroup}>
            <RadioButton
              name="addressMatches"
              label="Да"
              value="yes"
              checked={addressMatches}
              onChange={() => handleAddressMatchesChange('yes')}
            />
            <RadioButton
              name="addressMatches"
              label="Нет"
              value="no"
              checked={!addressMatches}
              onChange={() => handleAddressMatchesChange('no')}
            />
          </div>

          {!addressMatches && (
            <CustomInput
              name="actualAddress"
              type={InputTypeEnum.Text}
              placeholder="ФАКТИЧЕСКИЙ АДРЕС ПРОЖИВАНИЯ"
              description="Страна, город, улица, дом, корпус и квартира"
              required={!addressMatches}
              hideIcon
            />
          )}
        </div>
      </form>
    </FormProvider>
  );
};
