import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    setCurrentStep,
    setRequestId,
    setSelectedBank,
    setSelectedProduct
} from 'features/request/requestSlice';
import {
    selectCurrentStep,
    selectSelectedBank,
    selectSelectedProduct,
    selectRequestId,
    selectUserId
} from 'features/request/requestSelectors';
import { store } from 'app/store';

import Step0 from 'components/RequestCard/Step0';
import Step1 from 'components/RequestCard/Step1';
import Step2 from 'components/RequestCard/Step2';
import Step3 from 'components/RequestCard/Step3';
import Step4 from 'components/RequestCard/Step4';
import Step5 from 'components/RequestCard/Step5';
import Step6 from 'components/RequestCard/Step6';
import Step7 from 'components/RequestCard/Step7';
import Step8 from 'components/RequestCard/Step8';
import Step9 from 'components/RequestCard/Step9';
import Step10 from 'components/RequestCard/Step10';

import ProgressSteps from 'components/ProgressSteps/ProgressSteps';
import { BottonSteps } from 'components/BottonSteps';
import RequestCardLayout from 'app/layouts/RequestCardLayout/RequestCardLayout';

import { useGetCardRequestByIdQuery, useCreateCardRequestMutation, useUpdateRequestStepMutation, useRequestSmsCodeMutation, useGeneratePepMutation, useSignDocumentMutation } from 'services/api';
// import type { SignDocumentRequest } from 'services/api';

import styles from './RequestCardPage.module.scss';

interface RequestData {
    selectedBank: string;
    selectedProduct: string;
    personalData?: {
        [key: string]: any;
    };
    additionalData?: {
        [key: string]: any;
    };
    status?: string;
}

interface RequestCardPageProps {
    // Add props type here if needed
}

const RequestCardPage: React.FC<RequestCardPageProps> = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const step = useSelector(selectCurrentStep);
    const selectedBank = useSelector(selectSelectedBank);
    const selectedProduct = useSelector(selectSelectedProduct);
    const requestId = useSelector(selectRequestId);
    const userId = useSelector(selectUserId);

    const [isSendingSms, setIsSendingSms] = useState(false);
    const [smsError, setSmsError] = useState<string | null>(null);
    const [skipStep8, setSkipStep8] = useState(false);
    const [currentStatus, setCurrentStatus] = useState<number>();
    // console.log("🚀 ~ file: RequestCardPage.tsx:71 ~ currentStatus:", currentStatus);

    // Следим за изменением статуса
    // useEffect(() => {
    //     console.log("🚀 ~ file: RequestCardPage.tsx ~ setCurrentStatus вызван с:", currentStatus);
    // }, [currentStatus]);

    const [isStep1Valid, setIsStep1Valid] = useState(false);
    const [isStep3Valid, setIsStep3Valid] = useState(false);
    const [isStep4Valid, setIsStep4Valid] = useState(false);
    const [isStep5Valid, setIsStep5Valid] = useState(false);
    const [isStep6Valid, setIsStep6Valid] = useState(false);
    const [isStep7Valid, setIsStep7Valid] = useState(false);
    const [isStep8Valid, setIsStep8Valid] = useState(false);
    const [isStep9Valid, setIsStep9Valid] = useState(false);
    const [isStep10Valid, setIsStep10Valid] = useState(false);

    const TOTAL_STEPS = 10;
    const disableNextStepOff = false;

    const [createCardRequest] = useCreateCardRequestMutation();
    const [updateRequestStep] = useUpdateRequestStepMutation();
    const [requestSmsCode] = useRequestSmsCodeMutation();
    const [generatePep] = useGeneratePepMutation();
    const [signDocument] = useSignDocumentMutation();

    const { data: requestData } = useGetCardRequestByIdQuery(requestId, {
        skip: !requestId
    });

    // Эффект для инициализации requestId и шага из URL параметров
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const stepFromUrl = params.get('step');
        const requestIdFromUrl = params.get('requestId');

        if (requestIdFromUrl) {
            dispatch(setRequestId(requestIdFromUrl));
        }

        if (stepFromUrl) {
            const stepNumber = parseInt(stepFromUrl, 10);
            if (!isNaN(stepNumber) && stepNumber >= 0 && stepNumber <= TOTAL_STEPS) {
                dispatch(setCurrentStep(stepNumber));
            }
        }

        // Убираем параметры из URL
        if (stepFromUrl || requestIdFromUrl) {
            navigate('/request-card', { replace: true });
        }
    }, [dispatch, location.search, navigate]);

    // Эффект для инициализации requestId из навигации
    useEffect(() => {
        const navigationRequestId = location.state?.requestId;
        if (navigationRequestId) {
            dispatch(setRequestId(navigationRequestId));
            // Убираем параметры из URL
            navigate('/request-card', { replace: true });
        }
    }, [dispatch, location.state, navigate]);

    // Эффект для синхронизации данных из requestData
    useEffect(() => {
        if (requestData) {
            // Сначала проверяем selectedBank и selectedProduct
            if (requestData.selectedBank && requestData.selectedProduct) {
                dispatch(setSelectedBank(requestData.selectedBank));
                dispatch(setSelectedProduct(requestData.selectedProduct));
            }
            // Если их нет, пробуем взять из offer
            else if (requestData.offer?.name && requestData.offer?.category) {
                dispatch(setSelectedBank(requestData.offer.name));
                dispatch(setSelectedProduct(requestData.offer.category));
            }
        }
    }, [requestData, dispatch]);

    // Эффект для редиректа при отмененной заявке
    useEffect(() => {
        if (requestData?.status === 'canceled') {
            navigate('/main');
        }
    }, [requestData?.status, navigate]);

    const handleNext = () => {
        dispatch(setCurrentStep(step + 1));
        // Убираем параметры из URL
        navigate('/request-card', { replace: true });
    };

    const handleBack = () => {
        if (step === 1) {
            // Запрещаем возврат на шаг 0
            return;
        }
        dispatch(setCurrentStep(step - 1));
        // Убираем параметры из URL
        navigate('/request-card', { replace: true });
    };

    const handleStepClick = (stepNumber: number) => {
        // Запрещаем переход на шаг 0 и переход на шаг больше текущего
        if (stepNumber === 0 || stepNumber > step) {
            return;
        }
        dispatch(setCurrentStep(stepNumber));
        // Убираем параметры из URL
        navigate('/request-card', { replace: true });
    };

    const handleNextStep = async (data?: any) => {
        try {
            // Если это первый шаг и у нас нет requestId, создаем новую заявку
            if (step === 0) {
                if (!requestId && selectedBank && selectedProduct && userId) {
                    try {
                        const response = await createCardRequest({
                            selectedBank,
                            selectedProduct,
                            userId,
                        }).unwrap();
                        
                        // Сначала сохраняем ID в Redux
                        dispatch(setRequestId(response._id));
                        
                        // Затем используем ID напрямую из response для обновления шага
                        await updateRequestStep({
                            id: response._id, // Используем ID из response вместо requestId из state
                            step: step + 1,
                            // data: data
                        }).unwrap();

                        handleNext();
                        return;
                    } catch (error: any) {
                        console.error('Ошибка при создании заявки:', error);
                        alert('Произошла ошибка при создании заявки. Пожалуйста, попробуйте еще раз.');
                        return;
                    }
                } else {
                    if (!selectedBank || !selectedProduct) {
                        alert('Пожалуйста, выберите банк и продукт');
                    }
                    return;
                }
            }

            // Для шага 1 берем данные из Redux store
            if (step === 1 && requestId) {
                const personalData = store.getState().request.data.personalData;
                await updateRequestStep({
                    id: requestId,
                    step: step,
                    data: personalData
                }).unwrap();
                handleNext();
                return;
            }

            // Для шага 3 берем данные из Redux store
            if (step === 3 && requestId) {
                const additionalData = store.getState().request.data.additionalData;
                await updateRequestStep({
                    id: requestId,
                    step: step,
                    data: additionalData
                }).unwrap();
                handleNext();
                return;
            }

            if (step === 4 && requestId) {
                try {
                    // Увеличиваем данные шага
                    const updatedData = await updateRequestStep({
                        id: requestId,
                        step: step,
                        data: data
                    }).unwrap();

                    // Если телефон уже верифицирован, переходим к следующему шагу без отправки SMS
                    if (updatedData.data?.phoneVerified) {
                        console.log(" ~ RequestCardPage ~ Phone is already verified in DB, skipping SMS");
                        handleNext();
                        return;
                    }

                    // Защита от повторных вызовов
                    if (isSendingSms) {
                        console.log(" ~ RequestCardPage ~ SMS sending already in progress");
                        return;
                    }

                    setIsSendingSms(true);

                    handleNext(); // Всегда переходим на следующий шаг

                    const result = await requestSmsCode(requestId).unwrap();
                    if (result.smsResult?.includes('ERROR')) {
                        setSmsError(result.smsResult);
                    } else {
                        setSmsError(null);
                    }
                } catch (error) {
                    console.error('Ошибка при отправке SMS:', error);
                    setSmsError('Ошибка при отправке SMS');
                } finally {
                    setIsSendingSms(false);
                }
                return;
            }

            if (step === 5 && requestId && requestData?.personalData) {
                try {
                    // SMS код уже проверен в Step5, сразу переходим к подписанию
                    const { personalData } = requestData;

                    // Склеиваем ФИО, убирая лишние пробелы, если отчество отсутствует
                    const fullName = [personalData.surname, personalData.name, personalData.patronymic].filter(Boolean).join(' ');

                    // Формируем номер паспорта
                    const passportNumber = personalData.passportNumber;

                    const signData = {
                        phone: personalData.phone,
                        fullName,
                        passportNumber,
                        documentType: 'offer'
                    } as const;

                    const signResult = await signDocument(signData).unwrap();

                    if (!signResult.success) {
                        throw new Error(signResult.message || 'Ошибка при подписании документов');
                    }

                    // После успешного подписания переходим к следующему шагу
                    handleNext();
                } catch (error) {
                    console.error('Ошибка при подписании документов:', error);
                    setSmsError('Ошибка при подписании документов');
                }
                return;
            }

            // Если мы на шаге 7 и нужно пропустить шаг 8
            if (step === 7 && skipStep8) {
                dispatch(setCurrentStep(9));
                return;
            }

            // if (step === 8 && requestId) {
            //     // Генерируем PEP только если статус undefined или -1
            //     if (currentStatus === undefined || currentStatus === -1) {
            //         await generatePep(requestId).unwrap();
            //         handleNext();
            //         return;
            //     } else {
            //         // Если статус не -1, просто переходим к следующему шагу
            //         handleNext();
            //         return;
            //     }
            // }

            if ((step === 8 || step === 9) && requestId) {
                try {
                    // Увеличиваем данные шага
                    await updateRequestStep({
                        id: requestId,
                        step: step + 1,
                        // data: data
                    }).unwrap();

                    handleNext();
                } catch (error) {
                    console.error('Ошибка при обновлении шага 8/9:', error);
                    alert('Произошла ошибка при сохранении данных. Пожалуйста, попробуйте еще раз.');
                }
                return;
            }

            // Сохраняем данные только при переходе вперед
            if (data && requestId && step < TOTAL_STEPS - 1) {
                try {
                    await updateRequestStep({
                        id: requestId,
                        step: step,
                        data: data
                    }).unwrap();

                    // Переход к следующему шагу
                    handleNext();
                } catch (error) {
                    console.error('Ошибка при сохранении данных:', error);
                    alert('Произошла ошибка при сохранении данных. Пожалуйста, попробуйте еще раз.');
                }
            } else if (!requestId) {
                console.error('RequestId is null when trying to update step');
                alert('Ошибка: не найден ID заявки. Пожалуйста, начните заново.');
                // Возвращаемся к началу
                dispatch(setCurrentStep(0));
            } else {
                // Если нет данных для сохранения, просто переходим к следующему шагу
                handleNext();
            }

        } catch (error: any) {
            console.error('Общая ошибка:', error);
            alert('Произошла непредвиденная ошибка. Пожалуйста, попробуйте еще раз.');
        }
    };

    return (
        <RequestCardLayout>
            <div className={styles.requestCard}>
                <ProgressSteps
                    currentStep={step}
                    totalSteps={TOTAL_STEPS}
                    onStepClick={handleStepClick}
                    requestId={requestId}
                />

                <div className={styles.requestCardContent}>
                    {step === 0 ? (
                        <Step0
                            selectedBank={selectedBank || ''}
                            selectedProduct={selectedProduct || ''}
                            onBankChange={(bank) => dispatch(setSelectedBank(bank))}
                            onProductChange={(product) => dispatch(setSelectedProduct(product))}
                        />
                    ) : step === 1 ? (
                        <Step1
                            requestId={requestId}
                            setIsFormValid={setIsStep1Valid}
                            onFormSubmit={handleNextStep}
                            defaultValues={requestData?.personalData}
                        />
                    ) : step === 2 ? (
                        <Step2 requestId={requestId} />
                    ) : step === 3 ? (
                        <Step3
                            requestId={requestId}
                            setIsFormValid={setIsStep3Valid}
                            onFormSubmit={handleNextStep}
                        />
                    ) : step === 4 ? (
                        <Step4
                            requestId={requestId}
                            setIsFormValid={setIsStep4Valid}
                            onFormSubmit={handleNextStep}
                            setRequestId={(id) => dispatch(setRequestId(id))}
                        />
                    ) : step === 5 ? (
                        <Step5
                            requestId={requestId!}
                            onSubmit={handleNextStep}
                        />
                    ) : step === 6 ? (
                        <Step6
                            requestId={requestId || ''}
                            setIsFormValid={setIsStep6Valid}
                        />
                    ) : step === 7 ? (
                        <Step7
                            requestId={requestId || ''}
                            setIsFormValid={setIsStep7Valid}
                            setSkipNextStep={setSkipStep8}
                        />
                    ) : step === 8 ? (
                        <Step8
                            requestId={requestId || ''}
                            onFormSubmit={handleNextStep}
                            setIsFormValid={setIsStep8Valid}
                            onStatusChange={setCurrentStatus}
                        />
                    ) : step === 9 ? (
                        <Step9
                            requestId={requestId || ''}
                            setIsFormValid={setIsStep9Valid}
                            onStatusChange={setCurrentStatus}
                        />
                    ) : (
                        <Step10
                            requestId={requestId || ''}
                            bidId={requestId || undefined}
                            setIsFormValid={setIsStep10Valid}
                            onStatusChange={setCurrentStatus}
                        />
                    )}
                </div>

                {step !== 10 && (
                    <BottonSteps
                        step={step}
                        onNext={handleNextStep}
                        onPrev={handleBack}
                        requestId={requestId || ''}
                        disableNext={
                            disableNextStepOff
                                ? false
                                : (step === 0 && (!selectedBank || !selectedProduct)) ||
                                (step === 1 && !isStep1Valid) ||
                                (step === 3 && !isStep3Valid) ||
                                (step === 4 && !isStep4Valid) ||
                                (step === 5 && !requestData?.phoneVerified) ||
                                (step === 6 && !isStep6Valid) ||
                                (step === 7 && !isStep7Valid) ||
                                (step === 8 && !isStep8Valid) || // && (currentStatus !== 6) && currentStatus !== 0
                                (step === 9 && !isStep9Valid)
                        }
                    />
                )}
            </div>
        </RequestCardLayout>
    );
};

export default RequestCardPage;