import React from 'react';
import { useUpdateSandboxStatusMutation } from '../../../services/api';
// import { Tooltip } from 'components/ui/Tooltip';
import InfoIcon from 'assets/icons/info.svg';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

import styles from './SliderSwitch.module.scss';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

interface SliderSwitchProps {
    currentStep: number;
    bidId: string;
    initialSandbox?: boolean;
    onChange?: (isChecked: boolean) => void;
    refetch?: () => void;
}

const SliderSwitch: React.FC<SliderSwitchProps> = ({ currentStep, bidId, initialSandbox = false, onChange, refetch }) => {
    const [isChecked, setIsChecked] = React.useState(initialSandbox);
    const [isLoading, setIsLoading] = React.useState(false);
    const [updateSandbox] = useUpdateSandboxStatusMutation();

    const handleChange = async (newValue: boolean) => {
        if (isLoading) return; // Предотвращаем повторные запросы во время загрузки
        if (currentStep !== 1) return; // Запрещаем изменение sandbox для шага 0
        try {
            setIsLoading(true);
            await updateSandbox({ id: bidId, sandbox: newValue }).unwrap();
            setIsChecked(newValue); // Обновляем UI только после успешного ответа
            onChange?.(newValue);
            refetch?.(); // Вызываем refetch после успешного обновления
        } catch (error) {
            console.error('Ошибка при обновлении sandbox статуса:', error);
            // Не меняем состояние, так как мы его еще не изменили
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <LightTooltip title="Активно только на первом шаге" placement="top">
            <div className={styles.container}>
                <div className={styles.sliderContainer}>
                    <div
                        className={`${styles.option} ${!isChecked ? styles.active : ''} ${isLoading ? styles.loading : ''}`}
                        onClick={() => handleChange(false)}
                    >
                        ЗАЯВКА
                    </div>
                    <div
                        className={`${styles.option} ${isChecked ? styles.active : ''} ${isLoading ? styles.loading : ''}`}
                        onClick={() => handleChange(true)}
                    >
                        ОБУЧЕНИЕ
                    </div>
                </div>
            </div>
        </LightTooltip>
    );
};

export default SliderSwitch;
