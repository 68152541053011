import React, { useState, useEffect } from "react";
import {
  useUploadFilesMutation,
  useUploadFilesSandboxMutation,
  useGetCardRequestByIdQuery,
} from "services/api";
import { useDropzone } from "react-dropzone";
import ButtonUI from "components/ui/ButtonUI/ButtonUI";
import { TextUI } from "components/ui/TextUI/ui/TextUI";
import { CardWrapper } from "components/CardWrapper/CardWrapper";
import { Icon } from "@iconify/react";
import { PulseLoader } from "react-spinners";
import { Tooltip } from "components/ui/Tooltip";
import InfoIcon from "assets/icons/info.svg";
import FileIcon from "assets/icons/file_icon.svg";
import { useMediaQuery } from "shared/hooks/UseMediaQuery";
import { formatFileName, createFileWithNewName, FileType } from "components/PhotoForm/utils/fileUtils";

import styles from "./PepConsentPhotoForm.module.scss";

interface PepConsentPhotoFormProps {
  requestId: string;
  setIsFormValid?: (isValid: boolean) => void;
  onUploadSuccess?: () => void;
  serverData?: {
    sandbox?: boolean;
    pepPhoto?: string;
    documentPaths?: {
      PEP?: string;
    };
    bucketUploadedStatuses?: {
      BLANKPEP?: number;
    };
  };
  isGeneratingPep?: boolean;
  disabled?: boolean;
  messageType?: 'success' | 'error';
  statusMessage?: string;
  statusCode?: number;
}

const validateFileType = (file: File) => {
  const validTypes = ["image/jpeg", "image/png", "application/pdf"];
  return validTypes.includes(file.type);
};

const getFileName = (file: string | File) => {
  if (typeof file === "string") {
    return file.split("/").pop() || file;
  }
  return file.name;
};

export const PepConsentPhotoForm: React.FC<PepConsentPhotoFormProps> = ({
  requestId,
  setIsFormValid,
  onUploadSuccess,
  serverData,
  isGeneratingPep = false,
  disabled = false,
  messageType,
  statusMessage,
  statusCode
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [fileUrls, setFileUrls] = useState<string[]>([]);
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const { data: cardRequestData, refetch } = useGetCardRequestByIdQuery(requestId);
  const [uploadFiles] = useUploadFilesMutation();
  const [uploadFilesSandbox] = useUploadFilesSandboxMutation();

  const isMobile = useMediaQuery(768);
  const stringLength = isMobile ? 10 : 25;

  useEffect(() => {
    if (cardRequestData) {
      if (serverData?.sandbox && cardRequestData.pepPhoto) {
        const filePath = cardRequestData.pepPhoto;
        setFileUrls([filePath]);
        setUploadSuccess(true);
        setIsFormValid?.(true);
      } else if (cardRequestData.documentPaths?.PEP) {
        const filePath = cardRequestData.documentPaths.PEP;
        setFileUrls([filePath]);
        setUploadSuccess(true);
        setIsFormValid?.(true);
      } else {
        setFileUrls([]);
        setUploadSuccess(false);
      }
    }
  }, [cardRequestData, setIsFormValid, serverData?.sandbox]);

  // Проверяем статус загрузки файла
  useEffect(() => {
    if (serverData?.bucketUploadedStatuses) {
      const hasValidBlankPep = serverData.sandbox ? !!serverData.pepPhoto : serverData.bucketUploadedStatuses.BLANKPEP === 1;

      if (hasValidBlankPep) {
        setUploadSuccess(true);
        setIsFormValid?.(true);
      } else {
        setUploadSuccess(false);
        setIsFormValid?.(false);
      }
    }
  }, [serverData?.bucketUploadedStatuses, setIsFormValid]);

  const onDrop = async (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const isValid = validateFileType(file);

      if (!isValid) {
        setUploadError("Ошибка. Поддерживаются только файлы JPG, PNG и PDF.");
        return;
      }

      const isDuplicate = files.some(existingFile => existingFile.name === file.name) ||
        fileUrls.some(url => url.includes(file.name));

      if (isDuplicate) {
        setUploadError("Этот файл уже выбран");
        return;
      }

      setFiles([file]);
      setUploadError(null);
      setUploadSuccess(false);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    disabled: disabled || isLoading || isGeneratingPep,
    accept: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
      'application/pdf': ['.pdf']
    },
    maxFiles: 1,
    noClick: true,
    noKeyboard: true
  });

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDeleteFile = (index: number) => {
    if (index < fileUrls.length) {
      setFileUrls(prevUrls => prevUrls.filter((_, i) => i !== index));
      setIsFormValid?.(false);
    } else {
      setFiles([]);
    }
  };

  const handleUpload = async () => {
    if (files.length === 0) {
      setUploadError("Пожалуйста, выберите файл");
      return;
    }

    setIsLoading(true);
    setUploadError(null);

    try {
      const originalFile = files[0];
      const newFileName = formatFileName(requestId, 'BLANKPEP', originalFile);
      const file = createFileWithNewName(originalFile, newFileName);

      if (serverData?.sandbox) {
        const formData = new FormData();
        formData.append('pepFiles', file);
        await uploadFilesSandbox({
          id: requestId,
          formData
        }).unwrap();
      } else {
        const base64 = await new Promise<string>((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64 = reader.result as string;
            resolve(base64.split(',')[1]);
          };
          reader.readAsDataURL(file);
        });

        await uploadFiles({
          id: requestId,
          files: [{
            name: file.name,
            base64
          }]
        }).unwrap();
      }

      setFiles([]);
      setUploadSuccess(true);
      onUploadSuccess?.();
      await refetch();
    } catch (error: any) {
      console.error('Ошибка при загрузке файла:', error);
      setUploadError(error.data?.message || 'Ошибка при загрузке файла');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <CardWrapper minWidth="512">
      <div className={styles.content}>
        <div className={styles.titleWrapper}>
          <TextUI variant="h2" className={styles.title}>
            Фото согласия на ПЭП
          </TextUI>
          <Tooltip
            maxWidth="310px"
            content={
              <div className={styles.tooltipWrapper}>
                Подписание согласия на бумаге с живой подписью Клиента и
                заверение живой подписью Агента
              </div>
            }
          >
            <img src={InfoIcon} alt="info" className={styles.infoIcon} />
          </Tooltip>
        </div>

        {statusMessage && !uploadSuccess && !(fileUrls.length > 0) && (
          <div className={`${styles.statusMessage} ${styles[messageType || 'error']}`}>
            {statusCode === -1 && <div className={styles.loader} />}
            {statusMessage}
          </div>
        )}

        <>
          {uploadSuccess || fileUrls.length > 0 ? (
            <div>
              <div className={styles.successBlock}>
                <Icon icon="mdi:check-circle" color="#4CAF50" width="24px" />
                <span>Фото успешно загружены</span>
              </div>
            </div>
          ) : (
            <div className={styles.uploadSection}>
              <div className={styles.header}>
                <div>Фото/сканы</div>
              </div>

              <div
                {...getRootProps()}
                className={`${styles.dropzone} ${disabled ? styles.disabled : ''}`}
              >
                <input {...getInputProps()} ref={fileInputRef} />
                <div className={styles.uploadContent}>
                  <ButtonUI
                    label="ЗАГРУЗИТЬ ФАЙЛЫ"
                    variant="main2"
                    onClick={handleUploadClick}
                    disabled={disabled}
                    width="228px"
                    height="42px"
                  />
                  <p className={styles.addImageText}>
                    {disabled
                      ? "Дождитесь успешной генерации PEP"
                      : isDragActive
                        ? "Перетащите файл сюда..."
                        : "Или перетащите изображения сюда"}
                  </p>
                </div>
              </div>

              {files.length > 0 && (
                <div className={styles.fileList}>
                  {files.map((file, index) => (
                    <div key={index} className={styles.fileItem}>
                      <div className={styles.fileInfo}>
                        <img
                          src={FileIcon}
                          alt={getFileName(file)}
                          className={styles.previewImage}
                        />
                        <span className={styles.fileName}>
                          {file.name.length > stringLength
                            ? `${file.name.substring(0, stringLength)}...`
                            : file.name}
                        </span>
                      </div>

                      <ButtonUI
                        label="Удалить"
                        variant="link"
                        height="28px"
                        onClick={() => handleDeleteFile(index)}
                        disabled={disabled || isLoading}
                      />
                    </div>
                  ))}
                </div>
              )}

              {files.length > 0 && (
                <ButtonUI
                  onClick={handleUpload}
                  disabled={isLoading || files.length === 0}
                  className={styles.uploadButton}
                  label={isLoading ? "" : "Отправить"}
                  variant="main"
                  width="100%"
                >
                  {isLoading && <PulseLoader color="#4caf50" size={8} />}
                </ButtonUI>
              )}
            </div>
          )}
        </>

        {uploadError && <div className={styles.error}>{uploadError}</div>}
      </div>
    </CardWrapper>
  );
};

export default PepConsentPhotoForm;
