import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'app/store';
import LoadingOverlay from 'components/ui/LoadingOverlay/LoadingOverlay';

interface PrivateRouteProps {
    redirectTo?: string;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ redirectTo = '/login' }) => {
    const isAuth = useSelector((state: RootState) => state.login.isAuth);
    const isAuthChecked = useSelector((state: RootState) => state.login.isAuthChecked);
    const token = localStorage.getItem('token');

    // Если нет токена, сразу редиректим
    if (!token) {
        return <Navigate to={redirectTo} />;
    }

    // Показываем лоадер только если есть токен и проверка авторизации не завершена
    if (!isAuthChecked) {
        return <LoadingOverlay />;
    }

    return isAuth ? <Outlet /> : <Navigate to={redirectTo} />;
};

export default PrivateRoute;
