import React, { useState, useEffect } from 'react';
import { TextUI } from 'components/ui/TextUI/ui/TextUI';
import { SMSCodeForm } from 'components/SmsCodeForm/SmsCodeForm';
import { useVerifySmsCodeMutation, useUpdateRequestStepMutation, useGetCardRequestByIdQuery } from 'services/api';
import styles from './Step5.module.scss';
import PulseLoader from 'react-spinners/PulseLoader';

interface Step5Props {
    requestId: string;
    onSubmit: (code: string, success: boolean) => void;
}

const Step5: React.FC<Step5Props> = ({ requestId, onSubmit }) => {
    const [smsError, setSmsError] = useState<string | null>(null);
    const [isVerified, setIsVerified] = useState(false);
    const [isSendingSms, setIsSendingSms] = useState(false);
    const [verifySmsCode] = useVerifySmsCodeMutation();
    const [updateRequestStep] = useUpdateRequestStepMutation();
    const { data: requestData, refetch } = useGetCardRequestByIdQuery(requestId);

    // Проверяем статус phoneVerified при загрузке и изменении данных
    useEffect(() => {
        if (requestData?.phoneVerified) {
            setIsVerified(true);
        }
        refetch();
    }, [requestData, refetch]);

    const handleSmsSubmit = async (code: string) => {
        setIsSendingSms(true);
        try {
            // Проверяем SMS код
            const response = await verifySmsCode({
                requestId,
                code
            }).unwrap();

            if (response.phoneVerified) {
                // Если код верный, обновляем статус в заявке
                await updateRequestStep({
                    id: requestId,
                    step: 5,
                    data: {
                        phoneVerified: true,
                        smsCode: code
                    }
                }).unwrap();

                setIsVerified(true);
                // Передаем код наверх для подписания
                onSubmit(code, true);
                setSmsError(null);
            } else {
                setSmsError(response.message || 'Неверный код');
                setIsVerified(false);
                onSubmit(code, false);
            }
        } catch (error) {
            console.error('Ошибка при проверке кода:', error);
            setSmsError('Ошибка при проверке кода');
            setIsVerified(false);
            onSubmit(code, false);
        } finally {
            setIsSendingSms(false);
        }
    };

    return (
        <div className={styles.container}>
            {isSendingSms ? (
                <div className={styles.loaderContainer}>
                    <div className={styles.smsSending}>Отправка SMS:</div>
                    <PulseLoader color="#71BE81" size={8} />
                </div>
            ) : smsError ? (
                <div className={styles.errorMessage}>
                    <div>Ошибка при отправке SMS: {smsError}</div>
                    <div>Пожалуйста, проверьте ваш номер телефона на Шаге 1</div>
                </div>
            ) : null}
            {/* <TextUI variant="h2" align="left">
                Подтверждение номера телефона
            </TextUI> */}
            <SMSCodeForm
                requestId={requestId}
                onSubmit={handleSmsSubmit}
                smsError={smsError}
                isVerified={isVerified}
            />
        </div>
    );
};

export default Step5;
