import React, { useState, useEffect, useRef } from 'react';
import { useCheckCreditCardMutation, useGetBidStatusQuery, useResetOperationStatusMutation } from '../../services/api'; //useGeneratePepMutation
import EanCardCheckForm from '../EanCardCheckForm/ui/EanCardCheckForm';
import { useMediaQuery } from 'shared/hooks/UseMediaQuery';

import styles from './Step7.module.scss';

const POLLING_INTERVAL = 3000; // 3 секунды
// const MAX_POLLING_ATTEMPTS = 20; // Максимальное количество попыток (1 минута)

interface Step7Props {
    requestId: string | null;
    setIsFormValid?: (isValid: boolean) => void;
    setSkipNextStep?: (skip: boolean) => void;
}

const Step7: React.FC<Step7Props> = ({ requestId, setIsFormValid, setSkipNextStep }) => {
    const [checkCreditCard] = useCheckCreditCardMutation();
    const [resetOperationStatus] = useResetOperationStatusMutation();
    // const [generatePep] = useGeneratePepMutation();
    const [isPolling, setIsPolling] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [statusMessage, setStatusMessage] = useState<string | null>(null);
    const [isSuccess, setIsSuccess] = useState(false);
    const isMobile = useMediaQuery(768);

    // Единый запрос для получения статуса
    const { data: statusData, refetch } = useGetBidStatusQuery(
        requestId ? { id: requestId, type: 'check_card' } : null,
        {
            pollingInterval: isPolling ? POLLING_INTERVAL : 0,
            refetchOnMountOrArgChange: true,
            skip: !requestId,
            refetchOnFocus: true,
            refetchOnReconnect: true
        }
    );

    // Запускаем refetch при изменении флага поллинга
    useEffect(() => {
        if (isPolling && requestId) {
            refetch();
        }
    }, [isPolling, requestId, refetch]);

    // Проверяем статусы
    useEffect(() => {
        if (!statusData || !requestId) return;

        const { partnerResponseCode } = statusData.message || {};
        if (partnerResponseCode === undefined) return;

        // Проверяем, нужно ли обновлять состояние
        const isSuccessStatus = partnerResponseCode === 0 || partnerResponseCode === 6;
        // console.log("🚀 ~ file: Step7.tsx:55 ~ isSuccessStatus:", isSuccessStatus)
        const isErrorStatus = partnerResponseCode === 1 || partnerResponseCode === 3;

        if (isSuccessStatus || isErrorStatus) {
            setIsPolling(false);
            setIsLoading(false);

            if (isErrorStatus) {
                setIsSuccess(false);
                setErrorMessage(statusData.message.hint || statusData.message.partnerResponseMessage || 'Техническая ошибка');
                setIsFormValid?.(false);
            } else {
                setIsSuccess(true);
                setStatusMessage('EAN прошел проверку');
                setErrorMessage(null);
                setIsFormValid?.(true);

                // if (requestId) {
                //     generatePep(requestId);
                // }
            }

            if (setSkipNextStep) {
                setSkipNextStep(partnerResponseCode === 6);
            }
        } else if (isPolling) {
            setStatusMessage('Проверка статуса карты...');
        }
    }, [statusData, requestId, isPolling, setIsFormValid, setSkipNextStep]); //generatePep

    // Сброс состояний при размонтировании
    useEffect(() => {
        return () => {
            if (setIsFormValid) {
                setIsFormValid(false);
            }
            setIsPolling(false);
            setErrorMessage(null);
            setStatusMessage(null);
        };
    }, [setIsFormValid]);

    const handleFormSubmit = async (data: { ean: string }) => {
        try {
            setIsLoading(true);
            setHasSubmitted(true);
            setErrorMessage(null);
            setStatusMessage(null);

            // Отправляем запрос на проверку карты
            const response = await checkCreditCard({ id: requestId, ean: data.ean }).unwrap();
            // console.log('Check card response:', response);

            if (response.error) {
                setErrorMessage(response.error);
                setIsSuccess(false);
                setIsLoading(false);
                if (setIsFormValid) {
                    setIsFormValid(false);
                }
            } else {
                // Запускаем пуллинг статуса
                setIsPolling(true);
            }
        } catch (error: any) {
            console.error('Ошибка при проверке карты:', error);
            setErrorMessage(error.message || 'Произошла ошибка при проверке карты');
            setIsSuccess(false);
            setIsLoading(false);
            setHasSubmitted(false);
            if (setIsFormValid) {
                setIsFormValid(false);
            }
        }
    };

    const handleReset = async () => {
        if (requestId) {
            try {
                // Сбрасываем статус операции в базе
                await resetOperationStatus({
                    id: requestId,
                    operationType: 'check-card'
                }).unwrap();

                // Сбрасываем только статусы, но не значение инпута
                setIsPolling(true); // Сразу включаем режим поллинга
                setErrorMessage(null);
                setStatusMessage(null);
                setIsSuccess(false);
                setIsLoading(true);
                setHasSubmitted(true);
                if (setIsFormValid) {
                    setIsFormValid(false);
                }
            } catch (error) {
                console.error('Ошибка при сбросе статуса:', error);
            }
        }
    };

    return (
        <div className={styles.wrapper}>
            <EanCardCheckForm
                onSubmit={handleFormSubmit}
                onReset={handleReset}
                isLoading={isLoading}
                isPolling={isPolling}
                hasSubmitted={hasSubmitted}
                errorMessage={errorMessage}
                statusMessage={statusMessage}
                isSuccess={isSuccess}
                isMobile={isMobile}
                showResetButton={!isSuccess && hasSubmitted && !isPolling}
            />
        </div>
    );
};

export default Step7;